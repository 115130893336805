
import {Box,Text,} from '@chakra-ui/react'

export function Footer() {
    return (
        <Box bg="gray.500" w="100%" p={4} >
            <Text color="gray.300" >
                대표: 황상필, 사업자 번호: 584-65-00467 <br/>
                주소: 서울시 서초구 잠원로 14길 3, 이메일: pedicle2021@gmail.com
            </Text>
        </Box>
        )
}