import { ChakraProvider } from "@chakra-ui/react"
import Router from 'next/router';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'
import { FuegoProvider } from '@nandorojo/swr-firestore'
import Fuego  from '../config/fuego'
import { Provider as NextAuthProvider } from "next-auth/client";
import initGA from '../lib/ga'
const firebaseConfig = {
  apiKey: "AIzaSyDG180CNSzctjh3OryEH29mry49-8B1ZA0",
  authDomain: "next-dpi.firebaseapp.com",
  projectId: "next-dpi",
  storageBucket: "next-dpi.appspot.com",
  messagingSenderId: "220499691290",
  appId: "1:220499691290:web:5cd7837274cc224c9ecea3",
  measurementId: "G-JPWFHHTVZE"
}
const fuego = new Fuego(firebaseConfig)
import { SketchProvider } from '../utils/state';
import { useEffect } from "react";
import { Navigation } from '../components/navigation'
import { Footer } from "../components/footer";
import {Box} from "@chakra-ui/react"



function MyApp({ Component, pageProps }) {
  useEffect(() => {
    initGA('UA-189763522-1', Router);
  }, [])
  return (
    <NextAuthProvider>
      <SketchProvider>
        <ChakraProvider>
          <FuegoProvider fuego={fuego}>
            <Navigation />
            <Box minH="82vh">
              <Component {...pageProps} />
            </Box>
            <Footer/>
          </FuegoProvider>
        </ChakraProvider>
      </SketchProvider>
    </NextAuthProvider>
  )
}

export default MyApp
