import { useReducer, useContext, createContext } from 'react'

const SketchStateContext = createContext();
const SketchDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COLOR':
      return {
        ...state,
        color: action.value
      };
    case 'SET_SCALE':
      return {
        ...state,
        scale: action.value
      };
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const SketchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    color: 'black',
    scale: 0.7,
  });
  return (
    <SketchDispatchContext.Provider value={dispatch}>
      <SketchStateContext.Provider value={state}>
        {children}
      </SketchStateContext.Provider>
    </SketchDispatchContext.Provider>
  )
}

export const useSketch = () => useContext(SketchStateContext)
export const useDispatchSketch = () => useContext(SketchDispatchContext)
