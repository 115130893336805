import firebase from 'firebase/app';

export default class Fuego {
  constructor(config) {
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    }
    this.db = firebase.app().firestore()
    this.auth = firebase.auth;
    this.functions = firebase.functions;
    this.storage = firebase.storage;
    this.firebase = firebase
    
  }
}
